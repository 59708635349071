const vehicle_statuses = {
    name: 'vehicle_statuses',
    title: 'Статусы транспорта',
    url: '/refs/vehicle_statuses',
    include: [],
    filterBy: ['name'],
    perms: {
        list: 'v1.vehicle_statuses.list',
        read: 'v1.vehicle_statuses.read',
        create: 'v1.vehicle_statuses.create',
        update: 'v1.vehicle_statuses.update',
        delete: 'v1.vehicle_statuses.delete',
    },
    listColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
        {
            name: 'is_vehicle_active',
            required: true,
            label: 'ТС активен',
            align: 'left',
            field: 'is_vehicle_active',
            sortable: true,
            type: 'checkbox',
        },
    ],
    editColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            field: 'title',
            sortable: true,
        },
        {
            name: 'is_vehicle_active',
            required: true,
            label: 'ТС активен',
            field: 'is_vehicle_active',
            type: 'checkbox',
        },
    ],
};

export {
    vehicle_statuses,
};
